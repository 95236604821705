import NavBar from '../components/NavBar';
import UnderCon from '../images/undercon.png';

const UnderConstruction = () => {
  return (
    <div>
      <NavBar />
      <div
        style={{
          marginTop: '8rem',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <span className="section-header center">
          This Page is Under Construction
        </span>
        <p className="paragraph-big center">
          Our team of engineers is still working on bringing this awesome
          feature to life! Your patience is greatly appreciated.
        </p>
        <div style={{ width: '20rem' }}>
          <img style={{ width: '20rem' }} alt="bigpc" src={UnderCon} />
        </div>
      </div>
    </div>
  );
};

export default UnderConstruction;
