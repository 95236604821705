import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Menu,
  MenuItem,
  styled,
  useMediaQuery,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import { ThemeProvider } from "@mui/material/styles";
import { useEffect, useRef, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Logo from "../images/logo-web.svg";
import navbarTheme from "../themes/navbar-theme";
import navbarItems from "../utilities/json/navbar-items.json";

const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

const SignUpButton = ({ className, onClick }) => {
  return (
    <NavItem
      item={{ caption: "Sign Up For Free", attributes: { to: "/newuser" } }}
      className={className}
      onClick={onClick}
    />
  );
};

const NavItemAccordionList = ({
  accordionId,
  caption,
  subItems,
  subItemClassName,
  subItemActiveClassName,
  onClick,
}) => {
  const [expandedItem, setExpandedItem] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedItem(isExpanded ? panel : false);
  };

  return (
    <Accordion
      expanded={expandedItem === accordionId}
      onChange={handleAccordionChange(accordionId)}
    >
      <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
        <div className="menu-item-link">{caption}</div>
      </AccordionSummary>
      <AccordionDetails>
        {subItems.map((subitem) => (
          <NavItem
            key={subitem.id}
            item={subitem}
            className={subItemClassName}
            activeClassName={subItemActiveClassName}
            onClick={onClick}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

const NavItemDropdownList = ({
  caption,
  subItems,
  subItemClassName,
  subItemActiveClassName,
  onClick,
}) => {
  const listRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleClick = (e) => {
    setIsExpanded(false);
    onClick(e);
  };

  useEffect(() => {
    const handleEvent = (e) => {
      if (e.type === "mousedown") {
        const isClickOutside = listRef.current && !listRef.current.contains(e.target);

        if (!isClickOutside) return;
      }

      setIsExpanded(false);
    };

    document.addEventListener("mousedown", handleEvent);
    document.addEventListener("keydown", handleEvent);
    window.addEventListener("resize", handleEvent);

    return () => {
      document.removeEventListener("mousedown", handleEvent);
      document.removeEventListener("keydown", handleEvent);
      window.removeEventListener("resize", handleEvent);
    };
  }, []);

  return (
    <section className={`nav-bar-list ${isExpanded && "expanded"}`} ref={listRef}>
      <article className="caption-container" onClick={() => setIsExpanded((val) => !val)}>
        <div className="nav-bar-link">{caption}</div>
        <ExpandMoreOutlinedIcon />
      </article>
      <article className="list-container">
        {subItems.map((subitem) => (
          <NavItem
            key={subitem.id}
            item={subitem}
            className={subItemClassName}
            activeClassName={subItemActiveClassName}
            onClick={handleClick}
          />
        ))}
      </article>
    </section>
  );
};

const NavItem = ({ item, className, activeClassName, onClick }) => {
  return (
    <NavLink
      className={className}
      activeClassName={activeClassName}
      onClick={onClick}
      {...item.attributes}
    >
      {item.caption}
    </NavLink>
  );
};

const NavBarAsDropdown = ({
  currentPath,
  anchorElement,
  handleOpenDropdown,
  handleCloseDropdown,
}) => {
  return (
    <section className="navbar-dropdown-container">
      <Link to="/" id="nav-logo">
        <img alt="SkillProfile logo" src={Logo} />
      </Link>
      <IconButton
        size="large"
        aria-label="SkillProfile top menu"
        aria-controls="navbar-dropdown"
        aria-haspopup="true"
        onClick={handleOpenDropdown}
        color="inherit"
        className="dropdown-button"
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="navbar-dropdown"
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElement)}
        onClose={handleCloseDropdown}
      >
        {navbarItems.map((item) =>
          item.type !== "list" ? (
            <MenuItem key={item.id} disabled={item.attributes.to === currentPath}>
              <NavItem
                item={item}
                className="menu-item-link"
                activeClassName="menu-item-link--active"
                currentPath={currentPath}
                onClick={handleCloseDropdown}
              />
            </MenuItem>
          ) : (
            <NavItemAccordionList
              key={item.id}
              accordionId={item.id}
              caption={item.caption}
              subItems={item.subitems}
              subItemClassName="menu-item-link"
              subItemActiveClassName="menu-item-link--active"
              onClick={handleCloseDropdown}
            />
          )
        )}
        <SignUpButton
          className="menu-item-link menu-item-link--sign-up"
          onClick={handleCloseDropdown}
        />
      </Menu>
    </section>
  );
};

const NavBarAsMenu = ({ handleCloseDropdown }) => {
  return (
    <section className="navbar-menu-container">
      <Link to="/" className="nav-logo-container">
        <div id="nav-logo">
          <img alt="SkillProfile logo" src={Logo} />
        </div>
      </Link>
      <div id="nav-menu">
        {navbarItems.map((item) =>
          item.type !== "list" ? (
            <NavItem
              key={item.id}
              item={item}
              className="nav-bar-link"
              activeClassName="nav-bar-link--active"
              onClick={handleCloseDropdown}
            />
          ) : (
            <NavItemDropdownList
              key={item.id}
              caption={item.caption}
              subItems={item.subitems}
              subItemClassName="nav-bar-link"
              subItemActiveClassName="nav-bar-link--active"
              onClick={handleCloseDropdown}
            />
          )
        )}
      </div>
      <SignUpButton className="nav-bar-link nav-bar-link--signup" onClick={handleCloseDropdown} />
    </section>
  );
};

const NavBar = () => {
  const location = useLocation();
  const [anchorElement, setAnchorElement] = useState(null);
  const shouldDisplayAsDropdown = useMediaQuery("(max-width: 1023px");

  const handleOpenDropdown = (event) => {
    setAnchorElement(event.currentTarget);
  };

  const handleCloseDropdown = () => {
    setAnchorElement(null);
  };

  return (
    <ThemeProvider theme={navbarTheme}>
      <CssBaseline />
      <header id="navbar" data-testid="navbar">
        {shouldDisplayAsDropdown ? (
          <NavBarAsDropdown
            currentPath={location.pathname}
            anchorElement={anchorElement}
            handleOpenDropdown={handleOpenDropdown}
            handleCloseDropdown={handleCloseDropdown}
          />
        ) : (
          <NavBarAsMenu handleCloseDropdown={handleCloseDropdown} />
        )}
      </header>
      <Offset />
    </ThemeProvider>
  );
};

export default NavBar;
