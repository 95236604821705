import { createBrowserHistory } from "history";
import { useEffect } from "react";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import "./App.scss";
import EmployerDash from "./components/EmployerDash";
import TopLines from "./images/top-lines.svg";
import AboutPage from "./pages/AboutPage";
import Appreciation from "./pages/Appreciation";
import ForEmployers from "./pages/ForEmployers";
import Home from "./pages/Home";
import Login from "./pages/Login";
import NewUserWelcome from "./pages/NewUserWelcome";
import Pricing from "./pages/Pricing";
import Privacy from "./pages/Privacy";
import Register from "./pages/Register";
import Results from "./pages/Results";
import Terms from "./pages/Terms";
import UnauthorizedFeedback from "./pages/UnauthorizedFeedback";
import UnderConstruction from "./pages/UnderConstruction";

const EMPLOYER = "employer";

const history = createBrowserHistory();

//TODO: add a browser param to unauthorized feedback
const App = () => {
  const currentUser = { name: "Colonel Sanders", id: "bobbaganoosh" };
  const host = window.location.host.split(".")[0];
  const isEmployer = host === EMPLOYER;

  useEffect(() => {
    document.documentElement.style.setProperty("--app-background-image-url", `url(${TopLines})`);
    document.documentElement.style.setProperty("--app-background-size", "contain");
  }, []);

  if (isEmployer) {
    return (
      <div className="App">
        <Router history={history}>
          <Route exact path="/" render={(props) => <EmployerDash currentUser={currentUser} />} />
        </Router>
      </div>
    );
  }

  return (
    <div className="App">
      <Router history={history}>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/about" component={AboutPage} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/welcome" render={(props) => <NewUserWelcome {...props} />} />
          <Route exact path="/newuser" render={(props) => <Register {...props} />} />
          <Route
            exact
            path="/feedback/:requestId"
            render={(props) => <UnauthorizedFeedback {...props} />}
          />
          <Route exact path="/pricing" component={Pricing} />
          <Route exact path="/underconstruction" component={UnderConstruction} />
          <Route exact path="/thankyou" component={Appreciation} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/employers" component={ForEmployers} />
          <Route exact path="/results/:userId/:profileUid" component={Results} />
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

export default App;
